@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Belleza%3Aital%2Cwght%400%2C400&family=Bitter%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Source+Sans+Pro%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C600%3B0%2C700%3B0%2C900&family=Lato%3Aital%2Cwght%400%2C100%3B0%2C300%3B0%2C400%3B0%2C700%3B0%2C900&display=swap");

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-vBK2Vsvk0hdA_font-family: "Inter", sans-serif;
  --mixin-vBK2Vsvk0hdA_font-size: 16px;
  --mixin-vBK2Vsvk0hdA_font-weight: 400;
  --mixin-vBK2Vsvk0hdA_font-style: normal;
  --mixin-vBK2Vsvk0hdA_color: #535353;
  --mixin-vBK2Vsvk0hdA_text-align: left;
  --mixin-vBK2Vsvk0hdA_text-transform: none;
  --mixin-vBK2Vsvk0hdA_line-height: 1.5;
  --mixin-vBK2Vsvk0hdA_letter-spacing: normal;
  --mixin-vBK2Vsvk0hdA_white-space: pre-wrap;
  --mixin-vBK2Vsvk0hdA_user-select: text;
  --mixin-vBK2Vsvk0hdA_text-decoration-line: none;
  --mixin-vBK2Vsvk0hdA_text-overflow: clip;
  --mixin-0FjOl6y6XZC4_color: #000000;
  --mixin-0FjOl6y6XZC4_font-weight: 900;
  --mixin-0FjOl6y6XZC4_font-size: 72px;
  --mixin-0FjOl6y6XZC4_line-height: 1;
  --mixin-0FjOl6y6XZC4_letter-spacing: -4px;
  --mixin-0FjOl6y6XZC4_white-space: pre-wrap;
  --mixin-g7mKtd7oJ5cB_color: #000000;
  --mixin-g7mKtd7oJ5cB_font-size: 48px;
  --mixin-g7mKtd7oJ5cB_font-weight: 700;
  --mixin-g7mKtd7oJ5cB_letter-spacing: -1px;
  --mixin-g7mKtd7oJ5cB_line-height: 1.1;
  --mixin-g7mKtd7oJ5cB_white-space: pre-wrap;
  --mixin-ka16Wc1CuKnV_color: #0070f3;
  --mixin-ka16Wc1CuKnV_white-space: pre-wrap;
  --mixin-2n3ZxOnDSVeg_color: #000000;
  --mixin-2n3ZxOnDSVeg_font-size: 32px;
  --mixin-2n3ZxOnDSVeg_font-weight: 600;
  --mixin-2n3ZxOnDSVeg_letter-spacing: -0.8px;
  --mixin-2n3ZxOnDSVeg_line-height: 1.2;
  --mixin-2n3ZxOnDSVeg_white-space: pre-wrap;
  --mixin-f2pC4JY2-d9X_color: #000000;
  --mixin-f2pC4JY2-d9X_font-size: 24px;
  --mixin-f2pC4JY2-d9X_font-weight: 600;
  --mixin-f2pC4JY2-d9X_letter-spacing: -0.5px;
  --mixin-f2pC4JY2-d9X_line-height: 1.3;
  --mixin-f2pC4JY2-d9X_white-space: pre-wrap;
  --mixin-cvjF8fQSUhfs_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-cvjF8fQSUhfs_border-bottom-color: #dddddd;
  --mixin-cvjF8fQSUhfs_border-bottom-style: solid;
  --mixin-cvjF8fQSUhfs_border-bottom-width: 1px;
  --mixin-cvjF8fQSUhfs_border-left-color: #dddddd;
  --mixin-cvjF8fQSUhfs_border-left-style: solid;
  --mixin-cvjF8fQSUhfs_border-left-width: 1px;
  --mixin-cvjF8fQSUhfs_border-right-color: #dddddd;
  --mixin-cvjF8fQSUhfs_border-right-style: solid;
  --mixin-cvjF8fQSUhfs_border-right-width: 1px;
  --mixin-cvjF8fQSUhfs_border-top-color: #dddddd;
  --mixin-cvjF8fQSUhfs_border-top-style: solid;
  --mixin-cvjF8fQSUhfs_border-top-width: 1px;
  --mixin-cvjF8fQSUhfs_border-bottom-left-radius: 3px;
  --mixin-cvjF8fQSUhfs_border-bottom-right-radius: 3px;
  --mixin-cvjF8fQSUhfs_border-top-left-radius: 3px;
  --mixin-cvjF8fQSUhfs_border-top-right-radius: 3px;
  --mixin-cvjF8fQSUhfs_font-family: "Inconsolata";
  --mixin-cvjF8fQSUhfs_padding-bottom: 1px;
  --mixin-cvjF8fQSUhfs_padding-left: 4px;
  --mixin-cvjF8fQSUhfs_padding-right: 4px;
  --mixin-cvjF8fQSUhfs_padding-top: 1px;
  --mixin-cvjF8fQSUhfs_white-space: pre-wrap;
  --mixin-w3jQtD4ooRFu_border-left-color: #dddddd;
  --mixin-w3jQtD4ooRFu_border-left-style: solid;
  --mixin-w3jQtD4ooRFu_border-left-width: 3px;
  --mixin-w3jQtD4ooRFu_color: #888888;
  --mixin-w3jQtD4ooRFu_padding-left: 10px;
  --mixin-w3jQtD4ooRFu_white-space: pre-wrap;
  --mixin-VR1007xk_L8I_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-VR1007xk_L8I_border-bottom-color: #dddddd;
  --mixin-VR1007xk_L8I_border-bottom-style: solid;
  --mixin-VR1007xk_L8I_border-bottom-width: 1px;
  --mixin-VR1007xk_L8I_border-left-color: #dddddd;
  --mixin-VR1007xk_L8I_border-left-style: solid;
  --mixin-VR1007xk_L8I_border-left-width: 1px;
  --mixin-VR1007xk_L8I_border-right-color: #dddddd;
  --mixin-VR1007xk_L8I_border-right-style: solid;
  --mixin-VR1007xk_L8I_border-right-width: 1px;
  --mixin-VR1007xk_L8I_border-top-color: #dddddd;
  --mixin-VR1007xk_L8I_border-top-style: solid;
  --mixin-VR1007xk_L8I_border-top-width: 1px;
  --mixin-VR1007xk_L8I_border-bottom-left-radius: 3px;
  --mixin-VR1007xk_L8I_border-bottom-right-radius: 3px;
  --mixin-VR1007xk_L8I_border-top-left-radius: 3px;
  --mixin-VR1007xk_L8I_border-top-right-radius: 3px;
  --mixin-VR1007xk_L8I_font-family: "Inconsolata";
  --mixin-VR1007xk_L8I_padding-bottom: 3px;
  --mixin-VR1007xk_L8I_padding-left: 6px;
  --mixin-VR1007xk_L8I_padding-right: 6px;
  --mixin-VR1007xk_L8I_padding-top: 3px;
  --mixin-VR1007xk_L8I_white-space: pre-wrap;
  --mixin-HhbnWKNpL3ed3_display: flex;
  --mixin-HhbnWKNpL3ed3_flex-direction: column;
  --mixin-HhbnWKNpL3ed3_align-items: stretch;
  --mixin-HhbnWKNpL3ed3_justify-content: flex-start;
  --mixin-HhbnWKNpL3ed3_list-style-position: outside;
  --mixin-HhbnWKNpL3ed3_padding-left: 40px;
  --mixin-HhbnWKNpL3ed3_position: relative;
  --mixin-HhbnWKNpL3ed3_list-style-type: disc;
  --mixin-HhbnWKNpL3ed3_white-space: pre-wrap;
  --mixin-d7FboYML19FqO_display: flex;
  --mixin-d7FboYML19FqO_flex-direction: column;
  --mixin-d7FboYML19FqO_align-items: stretch;
  --mixin-d7FboYML19FqO_justify-content: flex-start;
  --mixin-d7FboYML19FqO_list-style-position: outside;
  --mixin-d7FboYML19FqO_padding-left: 40px;
  --mixin-d7FboYML19FqO_position: relative;
  --mixin-d7FboYML19FqO_list-style-type: decimal;
  --mixin-d7FboYML19FqO_white-space: pre-wrap;
  --mixin-F6O7pv4mxRoPc_color: #000000;
  --mixin-F6O7pv4mxRoPc_font-size: 20px;
  --mixin-F6O7pv4mxRoPc_font-weight: 600;
  --mixin-F6O7pv4mxRoPc_letter-spacing: -0.3px;
  --mixin-F6O7pv4mxRoPc_line-height: 1.5;
  --mixin-F6O7pv4mxRoPc_white-space: pre-wrap;
  --mixin-ku0m2kkpnGqVs_color: #000000;
  --mixin-ku0m2kkpnGqVs_font-size: 16px;
  --mixin-ku0m2kkpnGqVs_font-weight: 600;
  --mixin-ku0m2kkpnGqVs_line-height: 1.5;
  --mixin-ku0m2kkpnGqVs_white-space: pre-wrap;
  --mixin-VMrYeFJ1gSRUj_color: #3291ff;
  --mixin-VMrYeFJ1gSRUj_white-space: pre-wrap;
  --mixin-zhU9ZnoPWhHg-_white-space: pre-wrap;
  --mixin-MN-uE3Nbj8Vi7_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-vBK2Vsvk0hdA_font-family);
  font-size: var(--mixin-vBK2Vsvk0hdA_font-size);
  font-weight: var(--mixin-vBK2Vsvk0hdA_font-weight);
  font-style: var(--mixin-vBK2Vsvk0hdA_font-style);
  color: var(--mixin-vBK2Vsvk0hdA_color);
  text-align: var(--mixin-vBK2Vsvk0hdA_text-align);
  text-transform: var(--mixin-vBK2Vsvk0hdA_text-transform);
  line-height: var(--mixin-vBK2Vsvk0hdA_line-height);
  letter-spacing: var(--mixin-vBK2Vsvk0hdA_letter-spacing);
  white-space: var(--mixin-vBK2Vsvk0hdA_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-0FjOl6y6XZC4_color);
  font-weight: var(--mixin-0FjOl6y6XZC4_font-weight);
  font-size: var(--mixin-0FjOl6y6XZC4_font-size);
  line-height: var(--mixin-0FjOl6y6XZC4_line-height);
  letter-spacing: var(--mixin-0FjOl6y6XZC4_letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-g7mKtd7oJ5cB_color);
  font-size: var(--mixin-g7mKtd7oJ5cB_font-size);
  font-weight: var(--mixin-g7mKtd7oJ5cB_font-weight);
  letter-spacing: var(--mixin-g7mKtd7oJ5cB_letter-spacing);
  line-height: var(--mixin-g7mKtd7oJ5cB_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-ka16Wc1CuKnV_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-2n3ZxOnDSVeg_color);
  font-size: var(--mixin-2n3ZxOnDSVeg_font-size);
  font-weight: var(--mixin-2n3ZxOnDSVeg_font-weight);
  letter-spacing: var(--mixin-2n3ZxOnDSVeg_letter-spacing);
  line-height: var(--mixin-2n3ZxOnDSVeg_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-f2pC4JY2-d9X_color);
  font-size: var(--mixin-f2pC4JY2-d9X_font-size);
  font-weight: var(--mixin-f2pC4JY2-d9X_font-weight);
  letter-spacing: var(--mixin-f2pC4JY2-d9X_letter-spacing);
  line-height: var(--mixin-f2pC4JY2-d9X_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-cvjF8fQSUhfs_font-family);
  border-radius: var(--mixin-cvjF8fQSUhfs_border-top-left-radius)
    var(--mixin-cvjF8fQSUhfs_border-top-right-radius)
    var(--mixin-cvjF8fQSUhfs_border-bottom-right-radius)
    var(--mixin-cvjF8fQSUhfs_border-bottom-left-radius);
  padding: var(--mixin-cvjF8fQSUhfs_padding-top)
    var(--mixin-cvjF8fQSUhfs_padding-right)
    var(--mixin-cvjF8fQSUhfs_padding-bottom)
    var(--mixin-cvjF8fQSUhfs_padding-left);
  border-top: var(--mixin-cvjF8fQSUhfs_border-top-width)
    var(--mixin-cvjF8fQSUhfs_border-top-style)
    var(--mixin-cvjF8fQSUhfs_border-top-color);
  border-right: var(--mixin-cvjF8fQSUhfs_border-right-width)
    var(--mixin-cvjF8fQSUhfs_border-right-style)
    var(--mixin-cvjF8fQSUhfs_border-right-color);
  border-bottom: var(--mixin-cvjF8fQSUhfs_border-bottom-width)
    var(--mixin-cvjF8fQSUhfs_border-bottom-style)
    var(--mixin-cvjF8fQSUhfs_border-bottom-color);
  border-left: var(--mixin-cvjF8fQSUhfs_border-left-width)
    var(--mixin-cvjF8fQSUhfs_border-left-style)
    var(--mixin-cvjF8fQSUhfs_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-w3jQtD4ooRFu_color);
  padding-left: var(--mixin-w3jQtD4ooRFu_padding-left);
  border-left: var(--mixin-w3jQtD4ooRFu_border-left-width)
    var(--mixin-w3jQtD4ooRFu_border-left-style)
    var(--mixin-w3jQtD4ooRFu_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-VR1007xk_L8I_font-family);
  border-radius: var(--mixin-VR1007xk_L8I_border-top-left-radius)
    var(--mixin-VR1007xk_L8I_border-top-right-radius)
    var(--mixin-VR1007xk_L8I_border-bottom-right-radius)
    var(--mixin-VR1007xk_L8I_border-bottom-left-radius);
  padding: var(--mixin-VR1007xk_L8I_padding-top)
    var(--mixin-VR1007xk_L8I_padding-right)
    var(--mixin-VR1007xk_L8I_padding-bottom)
    var(--mixin-VR1007xk_L8I_padding-left);
  border-top: var(--mixin-VR1007xk_L8I_border-top-width)
    var(--mixin-VR1007xk_L8I_border-top-style)
    var(--mixin-VR1007xk_L8I_border-top-color);
  border-right: var(--mixin-VR1007xk_L8I_border-right-width)
    var(--mixin-VR1007xk_L8I_border-right-style)
    var(--mixin-VR1007xk_L8I_border-right-color);
  border-bottom: var(--mixin-VR1007xk_L8I_border-bottom-width)
    var(--mixin-VR1007xk_L8I_border-bottom-style)
    var(--mixin-VR1007xk_L8I_border-bottom-color);
  border-left: var(--mixin-VR1007xk_L8I_border-left-width)
    var(--mixin-VR1007xk_L8I_border-left-style)
    var(--mixin-VR1007xk_L8I_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-HhbnWKNpL3ed3_display);
  flex-direction: var(--mixin-HhbnWKNpL3ed3_flex-direction);
  align-items: var(--mixin-HhbnWKNpL3ed3_align-items);
  justify-content: var(--mixin-HhbnWKNpL3ed3_justify-content);
  list-style-position: var(--mixin-HhbnWKNpL3ed3_list-style-position);
  padding-left: var(--mixin-HhbnWKNpL3ed3_padding-left);
  position: var(--mixin-HhbnWKNpL3ed3_position);
  list-style-type: var(--mixin-HhbnWKNpL3ed3_list-style-type);
  flex-column-gap: var(--mixin-HhbnWKNpL3ed3_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-d7FboYML19FqO_display);
  flex-direction: var(--mixin-d7FboYML19FqO_flex-direction);
  align-items: var(--mixin-d7FboYML19FqO_align-items);
  justify-content: var(--mixin-d7FboYML19FqO_justify-content);
  list-style-position: var(--mixin-d7FboYML19FqO_list-style-position);
  padding-left: var(--mixin-d7FboYML19FqO_padding-left);
  position: var(--mixin-d7FboYML19FqO_position);
  list-style-type: var(--mixin-d7FboYML19FqO_list-style-type);
  flex-column-gap: var(--mixin-d7FboYML19FqO_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-F6O7pv4mxRoPc_color);
  font-size: var(--mixin-F6O7pv4mxRoPc_font-size);
  font-weight: var(--mixin-F6O7pv4mxRoPc_font-weight);
  letter-spacing: var(--mixin-F6O7pv4mxRoPc_letter-spacing);
  line-height: var(--mixin-F6O7pv4mxRoPc_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-ku0m2kkpnGqVs_color);
  font-size: var(--mixin-ku0m2kkpnGqVs_font-size);
  font-weight: var(--mixin-ku0m2kkpnGqVs_font-weight);
  line-height: var(--mixin-ku0m2kkpnGqVs_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-VMrYeFJ1gSRUj_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
