.parent {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #000000;
  padding-left: 11px;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .parent {
    padding-left: 0px;
  }
}
.navigationBar:global(.__wab_instance) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  object-fit: cover;
  position: fixed;
  background: #000000;
  color: #535353;
  height: auto;
  left: 0px;
  top: 0px;
  z-index: 999;
  padding: 16px 40px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navigationBar:global(.__wab_instance) {
    height: 65px;
    flex-shrink: 0;
  }
}
.logoFillIn {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link___3Fu1V {
  display: flex;
}
.img__oIkNt {
  object-fit: cover;
  height: 40px;
}
.img__oIkNt > picture > img {
  object-fit: cover;
}
.img__oIkNt > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__qtkrL {
  object-fit: cover;
}
.img__qtkrL > picture > img {
  object-fit: cover;
}
.img__qtkrL > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__rGGvN {
  object-fit: cover;
}
.img__rGGvN > picture > img {
  object-fit: cover;
}
.img__rGGvN > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.link___9G3WT {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ac8d5b;
  font-family: "Belleza", sans-serif;
  padding-right: 10px;
}
.parent .link___9G3WT:hover {
  color: #ffffff;
}
.link__hBhyb {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ac8d5b;
  font-family: "Belleza", sans-serif;
  padding: 10px;
}
.parent .link__hBhyb:hover {
  color: #ffffff;
}
.link__u0UuZ {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ac8d5b;
  font-family: "Belleza", sans-serif;
}
.parent .link__u0UuZ:hover {
  color: #ffffff;
}
.columns {
  --plsmc-rc-col-gap: 0px;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: center;
  min-width: 0;
  padding: 86px 0px 0px;
}
.columns > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__h2SSy {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px 40px 0px 50px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__h2SSy {
    padding: 0px;
  }
}
.img__arOIp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 657px;
  margin-top: 113px;
  width: 511px;
  flex-shrink: 0;
  border-radius: 15px;
}
.img__arOIp > picture > img {
  object-fit: cover;
}
.img__arOIp > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__arOIp {
    height: 360px;
    margin-left: 0px;
    width: 547px;
    margin-top: 4px;
    flex-shrink: 0;
    border-radius: 0px;
  }
}
.column__hSpLz {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px 50px 8px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__hSpLz {
    padding-left: 30px;
    padding-top: 48px;
  }
}
.vinarijaGajic {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .vinarijaGajic {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.h2 {
  color: #ac8d5b;
  margin-bottom: 58px;
  font-family: "Bitter", serif;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2 {
    text-align: center;
  }
}
.text__nakec {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__nakec {
    text-align: center;
  }
}
.footer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #000000;
  min-width: 0;
  padding: 40px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .footer {
    height: auto;
    padding: 2px 0px 17px;
  }
}
.footerParentColumns {
  --plsmc-rc-col-gap: 0px;
}
.footerParentColumns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.footerParentColumns > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.footerParentColumns > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.footerParentColumns > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerParentColumns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerParentColumns > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__hdNxM {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 16px 0px #00000033, 0px 4px 16px 0px transparent;
  min-width: 0;
  padding: 8px;
  border: 0px none #ff0b0b;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__hdNxM {
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
  }
}
.text__ed96I {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__ookfO {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__ookfO {
    padding: 50px 0px;
  }
}
.logo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 50%;
}
.logo > picture > img {
  object-fit: cover;
}
.logo > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__grloR {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px 40px 8px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__grloR {
    padding: 0px;
  }
}
.text__aGdyr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  font-family: "Lato", sans-serif;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__aGdyr {
    text-align: center;
  }
}
.link__qnLyK {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  font-family: "Lato", sans-serif;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link__qnLyK {
    text-align: center;
  }
}
.parent .link__qnLyK:hover {
  color: var(--token-zy6xhYnWF8Y7);
}
