.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  color: #ffffff;
  min-width: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  transition-property: all;
  transition-duration: 0.4s;
  height: 427px;
  flex-shrink: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.4s;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.root:hover .img {
  transform: scaleX(1.1) scaleY(1.1) scaleZ(1);
}
.freeBox {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  padding-top: 20px;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.slotTargetChildren {
  font-family: "Belleza", sans-serif;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}
